<template>
  <div class="remote-deliver" :style="{'padding-bottom': isEdit || isApprove ?'96px':'12px'}">
      <DeliverBasicInfo :details="deliverDetail" :showDeliveryCostStatus="true"/>
      <div class="middle deliverItem">
          <div class="common-t">
              <div class="common-t-l">{{$t('异地交付信息')}}</div>
          </div>
          <van-form ref="remote" input-align="right" error-message-align="right" class="deliverInfo">
              <van-field
                  :label="$t('异地交付方式')"
                  :placeholder="$t('请选择')"
                  :value="methodMap[formData.deliveryMethod]"
                  readonly>
              </van-field>         
              <van-field v-model="formData.detailedAddr"
                :label="$t('客户详细地址')"
                :disabled="!isEdit"
                readonly/>
              <div class="common-title">{{ $t('费用信息') }}</div>
              <van-field v-model="formData.estimateDistance"
                :label="$t('里程数（公里）')"
                :disabled="!isEdit"
                readonly/>
              <div v-if="isOverDistance" class="km-tips">已大于{{ costConfig.distanceConfig }}公里</div>
              <van-field v-model="formData.estimateCost"  
                :label="$t('预估费用（元）')"
                :disabled="!isEdit"
                readonly/>
                <!-- 4007002异地到店(系统获取) 4007003送车上门(手动填写实际花费,最大万位,小数点后2位) -->
              <van-field v-model="formData.actualCost"
                required
                type="number" 
                :label="$t('实际花费（元）')"
                :placeholder="$t('请输入')"
                :rules="[{ pattern: /^[1-9]\d{0,4}(\.\d{1,2})?$|^0(\.\d{1,2})?$/, trigger: 'onChange', message: '最多只能输入5位整数位，小数点后至多2位' }]"
                :disabled="!isEdit || formData.deliveryMethod === '4007002'"/>
              <van-field required class="upload-img" :disabled="!isEdit" :rules="[{required:true,message: $t('请上传附件')}]">
                  <template #label>
                      <div class="label-tips">
                      <div>{{ $t('上传费用图片')}}</div>
                      </div>
                  </template>
                  <template #input>
                      <div>
                          <van-uploader
                              class="upload"
                              capture="camera"
                              accept="image/*"
                              :preview-options="{closeable: true}"
                              :before-read="beforeRead"
                              :deletable="isEdit"
                              :after-read="(file) => afterRead(file)"
                              :file-list="fileList"
                              :before-delete="(file) => beforeDelete(file)"
                              :max-count="9"
                              multiple/>
                      </div>
                  </template>
              </van-field>
          </van-form>
      </div>
      <!-- 驳回 -->
      <div v-if="['4014020', '4014025', '4014030'].includes(status)" class="approve-info deliverItem">
        <div> 
          <span>{{ operTypeMap[audit.operType] }}</span>
          <span class="ml4">{{ $t('驳回') }}</span>
          <span class="ml4">{{ audit.createTime }}</span>
        </div>
        <div>{{ $t('驳回原因') }}：</div>
        <div>{{ audit.rejectReason }}</div>
      </div>
     
      <div v-if="isEdit" class="common-footer">
          <van-button class="btn back" @click="goBack">{{$t('返回')}}</van-button>
          <van-button class="btn submit" @click="onSubmit">{{['4014020', '4014025', '4014030'].includes(status)?$t('重新发起申请'):$t('提交申请')}}</van-button>
      </div>
      <div v-if="isApprove" class="common-footer">
          <van-button class="btn back" @click="approve(2)">{{$t('驳回')}}</van-button>
          <van-button class="btn submit" @click="approve(1)">{{$t('通过')}}</van-button>
      </div>
      <!-- 审核弹窗 -->
      <van-dialog v-model="isShowReview" :title="$t('审核意见')" 
          show-cancel-button 
          :cancel-button-text="$t('取消')" 
          :confirm-button-text="$t('驳回')"
          :close-on-click-overlay="true"
          :before-close="onReview">
          <van-field
            v-model="reviewRemark"
            rows="2"
            autosize
            label=""
            type="textarea"
            maxlength="70"
            :placeholder="$t('请输入驳回原因')"
            show-word-limit
          />
        </van-dialog>
  </div>
</template>
<script>
import DeliverBasicInfo from '../deliverManage/component/deliver-basic-info.vue'
import loading from '@/utils/loading'
import deliveryServices from '@/services/deliveryServices.js'
import commonSelector from '@/components/common-selector'
import AddressSystem from '@/components/addressSystem.vue'
import { uploadFile } from '@/utils/cos'
import { v4 as uuidv4 } from 'uuid'
import { mapGetters } from 'vuex'
import { validate } from '@/utils/validate'

export default {
  components:{ DeliverBasicInfo,AddressSystem },
  data(){
    return {
      validate,
      operTypeMap : {
        0: this.$t('创建'),
        1: this.$t('店长'),
        2: this.$t('区域'),
        3: this.$t('总部')
      },
      id: '',
      isEdit: true,
      isApprove: false,
      status: '',
      deliverDetail:{},
      formData: {
        deliveryMethod: '',
        deliveryMethodName: '',
        // customerAddr: {
        //   addr: ''
        // },
        detailedAddr: '',
        offsiteCostUrl: '', // 异地花费图片
        actualCost: '', // 实际花费
        estimateDistance: '',
        estimateCost: ''
      },
      fileList: [],
      isShowReview: false,
      reviewRemark: '',
      audit:{},
      costConfig: {
        // sendToCost 每公里花费
        // distanceConfig 里程设置
        // comToCost: {
        //   greaterConfig
        //   lessConfig
        // }
      },
      isOverDistance: false
    }
  },
  computed:{
    ...mapGetters(['dictHash','userInfo']),
    methodMap(){
      const map = {}
      this.dictHash[4007].filter(e=>e.code!=='4007001').forEach(e=>{
        map[e.code] = e.name
      })
      return map
    }
  },
  mounted(){
    const { id } = this.$route.query
    this.id = id
    // 查询交车单详情
    this.getDetail()
    // 异地交付费用配置
    this.getCostConfig()
  },
  methods: {
    async getCostConfig(){
      const res = await deliveryServices.getDeliveryConfig()
      this.$set(this,'costConfig',res)
    },
    // 计算实际费用
    calcActualCost(){
      this.isOverDistance = this.formData.estimateDistance > this.costConfig.distanceConfig
      // 异地客户到店
      if (this.isOverDistance){
        this.formData.actualCost = this.costConfig.comToCost.greaterConfig
      } else {
        this.formData.actualCost = this.costConfig.comToCost.lessConfig
      }
    },
    // 获取交车详情
    async getDetail() {
      loading.showLoading()
      const res = await deliveryServices.deliveryDetail({ id: this.id })
      loading.hideLoading()
      this.deliverDetail = res || {}
      this.requestDetails()
    },
    // 申请详情
    async requestDetails(){
      const { offsiteCostUrl, customerAddr, estimateDistance, estimateCost, actualCost }=this.deliverDetail?.extend || {}
      const { deliveryMethod, deliveryCostStatus: status }=this.deliverDetail
      if (estimateDistance) this.formData.estimateDistance = estimateDistance
      if (estimateCost) this.formData.estimateCost = estimateCost
      if (actualCost) this.formData.actualCost = actualCost
      this.status = status || ''
      // 新增/4007001待审核/4014020驳回  则可填写
      this.isEdit = !status || ['4014020', '4014025', '4014030'].includes(status)
      if (['4014020', '4014025', '4014030'].includes(status)){
        this.getHistory()
      }
      // 交付店长&待审核
      this.isApprove = '4014001' === status && this.userInfo.roleLogos.includes('1014007')
      // 回显详情
      this.formData.deliveryMethod = deliveryMethod
      // this.formData.customerAddr = customerAddr
      const { provinceName, cityName, areaName, addr } = customerAddr
      this.formData.detailedAddr = provinceName + cityName + areaName + addr
      // 异地上门交付根据系统获取实际费用
      if (deliveryMethod === '4007002') {
        this.calcActualCost()
      }
      // 回显图片
      this.fileList = offsiteCostUrl.split(',').map(url => {
        return {
          url: url,
          cosUrl: url,
          status: 'done',
          message: '',
          uuid: uuidv4()
        }
      })
    },
    async getHistory(){
      const res = await deliveryServices.getReviewHistory(this.id)
      this.$set(this,'audit',res[res.length-1])
    },
    async selectField(field, name, dictType, multiple = false) {
      if (!this.isEdit) return
      // 不能直接用4007字典  因为要过滤掉到店交付的，所以走dictType: 'invalidReason',
      const _obj = {
        field,
        dictType: 'invalidReason',
        multiple,
        reasonList: this.dictHash[4007].filter(e=>e.code!=='4007001').map(e=>({ id:e.code,reason:e.name })),
        lastSelect: this.formData[field]
      }
      commonSelector(_obj).then(res => {
        this.formData[field] = res.dictCode
        this.formData[name] = res.dictName
      })
    },
    // 图片校验
    beforeRead(file) {
      const limitSize = 10 // 单位: mb
      // const str = file.name.substring(file.name.lastIndexOf('.') + 1)
      // const rules = ['jpg','png']
      // if (!rules.includes(str.toLocaleLowerCase())) {
      //   this.$toast.fail(this.$t('请上传正确图片文件(支持jpg/png)'))
      //   return false
      // }
      if (!file.type.includes('image')) {
        this.$toast.fail(this.$t('请上传正确图片文件'))
        return false
      }
      if (file.size > limitSize*1024*1024){
        this.$toast.fail(`${this.$t('文件不能超过')}${limitSize}Mb`)
        return false
      }
      
      return true
    },
    afterRead(file) {
      const uuid = uuidv4()
      const content = file.content
      file.status = 'uploading'
      file.message = '上传中...'
      file.uuid = uuid
      uploadFile(file.file).then(({ url }) => {
        if (file) {
          file.status = 'done'
          file.message = ''
          file.url = content
          file.cosUrl = url
          this.fileList.push(file)
        }
      }).catch(() => {
        file.status = 'failed'
        file.message = this.$t('上传失败')
      })
    },
    beforeDelete(file) {
      this.fileList = this.fileList.filter(item => item.uuid !== file.uuid)
    },
    goBack(){
      this.$router.go(-1)
    },
    async onSubmit(){
      loading.showLoading()
      // const p1 = this.$refs.addressSystem.$refs.form.validate()
      const p2 = this.$refs.remote.validate()
      // const fileUrlArr = []
      // this.fileList.forEach(item=>{
      //   if (item.status === 'done'){
      //     fileUrlArr.push(item.cosUrl)
      //   }
      // })
      let fileList = this.fileList || []
      // 已经签名的图片 需要截取掉签名信息之后 再传给后端
      fileList = fileList.map(({ cosUrl }) => {
        return cosUrl?.indexOf('?q-sign-algorithm') == -1 ? cosUrl : cosUrl.slice(0, cosUrl.indexOf('?q-sign-algorithm'))
      })
      this.formData.offsiteCostUrl = fileList.join(',')
      Promise.all([p2]).then(async()=>{
        const params = {
          orderId: this.id,
          offsiteCostUrl: this.formData.offsiteCostUrl,
          actualCost: this.formData.actualCost
        }
        const res = await deliveryServices.remoteDeliverCostRequest(params)
        loading.hideLoading()
        this.$toast.success(this.$t('提交成功'))
        setTimeout(() => {
          this.$router.go(-1)
        }, 500)
      }).catch(error=>{
        loading.hideLoading()
      })
      
    },
    // type 1通过 2驳回
    approve(type){
      if (type===2){
        this.isShowReview = true
        return 
      }
      this.approveRequest(type)
    },
    // 审批请求
    approveRequest(type,reason){
      const params = {
        orderIds: [this.id],
        result: type,
        rejectReason: type=== 2 ? reason : '',
        type: 2 // 1 异地 2 费用
      }
      deliveryServices.remoteDeliverAudit(params).then(res => {
        this.$toast.success(this.$t('提交成功'))
        setTimeout(() => {
          this.$router.go(-1)
        }, 500)
      })
    },
    // 审核驳回 1：取消、2：驳回
    async onReview(action, done){
      if (['overlay','cancel'].includes(action))done()

      if (action==='confirm'&&!this.reviewRemark.trim()) {
        this.$toast(this.$t('请输入审核意见'))
        done(false)
        return
      }
      if (['confirm'].indexOf(action)>-1){
        await this.approveRequest(2,this.reviewRemark)
        done()
      }
    }
  }
}
</script>
<style lang="less" scoped>
.remote-deliver{
  padding: 12px 16px;
  font-size: 14px;
  .deliverItem {
      background: #FFFFFF;
      box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.05);
      border-radius: 8px 8px 8px 8px;
      width: 100%;
      box-sizing: border-box;
      margin-top: 16px;
      .upload-img{
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          /deep/.van-field__label{
              max-width: 100%;
          }
          .placeholder-img{
              display: flex;
              flex-direction: column;
              color: #646566;
          }
      }
  }

  .middle {
      padding: 0 12px 12px;

      /deep/ .van-cell {
          padding: 10px 0;

          &::after {
              width: 100%;
              left: 0;
          }
      }
  }
  .approve-info{
    padding: 12px;
    font-size: 14px;
    color: #646566;
    line-height: 22px;
  }
  .ml4{
    margin-left: 4px;
  }
  .km-tips{
    display: flex;
    justify-content: flex-end;
    color: red
  }
}

</style>